/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    div: "div",
    h2: "h2",
    a: "a",
    h3: "h3",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "個人用、会社用などでgithubアカウントが複数あり、\n同じpcから異なるアカウントのリポジトリにpushすると"), "\n", React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<div class=\"gatsby-highlight\" data-language=\"bash\"><pre style=\"counter-reset: linenumber NaN\" class=\"language-bash line-numbers\"><code class=\"language-bash\"><span class=\"token function\">git</span> push <span class=\"token parameter variable\">-u</span> origin master\nERROR: Permission to user1/xxx.git denied to user2.</code><span aria-hidden=\"true\" class=\"line-numbers-rows\" style=\"white-space: normal; width: auto; left: 0;\"><span></span><span></span></span></pre></div>"
    }
  }), "\n", React.createElement(_components.p, null, "とエラーがでた。その時にやったことを書いておく"), "\n", React.createElement(_components.h2, {
    id: "ssh-keyを作成してgithubに登録",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#ssh-key%E3%82%92%E4%BD%9C%E6%88%90%E3%81%97%E3%81%A6github%E3%81%AB%E7%99%BB%E9%8C%B2",
    "aria-label": "ssh keyを作成してgithubに登録 permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "ssh keyを作成してgithubに登録"), "\n", React.createElement(_components.h3, {
    id: "ssh-keyの作成",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#ssh-key%E3%81%AE%E4%BD%9C%E6%88%90",
    "aria-label": "ssh keyの作成 permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "ssh keyの作成"), "\n", React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<div class=\"gatsby-highlight\" data-language=\"bash\"><pre style=\"counter-reset: linenumber NaN\" class=\"language-bash line-numbers\"><code class=\"language-bash\"><span class=\"token builtin class-name\">cd</span> ~/.ssh\nssh-keygen <span class=\"token parameter variable\">-f</span> <span class=\"token builtin class-name\">test</span> <span class=\"token parameter variable\">-t</span> rsa <span class=\"token parameter variable\">-C</span> <span class=\"token string\">\"user1@example.com\"</span>\nGenerating public/private rsa key pair.\nEnter passphrase <span class=\"token punctuation\">(</span>empty <span class=\"token keyword\">for</span> no passphrase<span class=\"token punctuation\">)</span>: \nEnter same passphrase again: \nYour identification has been saved <span class=\"token keyword\">in</span> test.\nYour public key has been saved <span class=\"token keyword\">in</span> test.pub.</code><span aria-hidden=\"true\" class=\"line-numbers-rows\" style=\"white-space: normal; width: auto; left: 0;\"><span></span><span></span><span></span><span></span><span></span><span></span><span></span></span></pre></div>"
    }
  }), "\n", React.createElement(_components.h3, {
    id: "githubに登録",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#github%E3%81%AB%E7%99%BB%E9%8C%B2",
    "aria-label": "githubに登録 permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "githubに登録"), "\n", React.createElement(_components.p, null, "githubのページから\nプロフィールアイコン->settings->SSH and GPG keys->add sshkey->test.pubの中身を登録"), "\n", React.createElement(_components.h2, {
    id: "sshconfigに接続先を設定",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#sshconfig%E3%81%AB%E6%8E%A5%E7%B6%9A%E5%85%88%E3%82%92%E8%A8%AD%E5%AE%9A",
    "aria-label": "sshconfigに接続先を設定 permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "~/.ssh/configに接続先を設定"), "\n", React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "\n        <div class=\"undefined\">\n          <span>~/.ssh/config</span>\n        </div>\n       "
    }
  }), "\n", React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<div class=\"gatsby-highlight\" data-language=\"ssh-config\"><pre style=\"counter-reset: linenumber NaN\" class=\"language-ssh-config line-numbers\"><code class=\"language-ssh-config\">---\n# user1 github\nHost github-user1\n  HostName github.com\n  User git\n  Port 22\n  TCPKeepAlive yes\n  IdentitiesOnly yes\n  IdentityFile ~/.ssh/test\n---</code><span aria-hidden=\"true\" class=\"line-numbers-rows\" style=\"white-space: normal; width: auto; left: 0;\"><span></span><span></span><span></span><span></span><span></span><span></span><span></span><span></span><span></span><span></span></span></pre></div>"
    }
  }), "\n", React.createElement(_components.p, null, "を追記"), "\n", React.createElement(_components.h2, {
    id: "git-remoteの登録",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#git-remote%E3%81%AE%E7%99%BB%E9%8C%B2",
    "aria-label": "git remoteの登録 permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "git remoteの登録"), "\n", React.createElement(_components.p, null, "remoteのurlに\n", React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<code class=\"language-text\">git@{~/.ssh/configのHostの名前}:{個人のアカウント名}/{APP名}.git</code>"
    }
  }), "\nを登録"), "\n", React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<div class=\"gatsby-highlight\" data-language=\"bash\"><pre style=\"counter-reset: linenumber NaN\" class=\"language-bash line-numbers\"><code class=\"language-bash\"><span class=\"token comment\"># 登録の場合</span>\n<span class=\"token function\">git</span> remote <span class=\"token function\">add</span> origin git@github-user1:<span class=\"token punctuation\">{</span>個人のアカウント名<span class=\"token punctuation\">}</span>/<span class=\"token punctuation\">{</span>APP名<span class=\"token punctuation\">}</span>.git\n<span class=\"token comment\"># 更新の場合</span>\n<span class=\"token function\">git</span> remote set-url origin git@github-user1:<span class=\"token punctuation\">{</span>個人のアカウント名<span class=\"token punctuation\">}</span>/<span class=\"token punctuation\">{</span>APP名<span class=\"token punctuation\">}</span>.git\n<span class=\"token function\">git</span> remote <span class=\"token parameter variable\">-v</span>\norigin\tgit@github-user1:<span class=\"token punctuation\">{</span>個人のアカウント名<span class=\"token punctuation\">}</span>/<span class=\"token punctuation\">{</span>APP名<span class=\"token punctuation\">}</span>.git <span class=\"token punctuation\">(</span>fetch<span class=\"token punctuation\">)</span>\norigin\tgit@github-user1:<span class=\"token punctuation\">{</span>個人のアカウント名<span class=\"token punctuation\">}</span>/<span class=\"token punctuation\">{</span>APP名<span class=\"token punctuation\">}</span>.git <span class=\"token punctuation\">(</span>push<span class=\"token punctuation\">)</span></code><span aria-hidden=\"true\" class=\"line-numbers-rows\" style=\"white-space: normal; width: auto; left: 0;\"><span></span><span></span><span></span><span></span><span></span><span></span><span></span></span></pre></div>"
    }
  }), "\n", React.createElement(_components.p, null, "でpushできるようになる"), "\n", React.createElement(_components.h2, {
    id: "おまけgit-config---localの設定",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#%E3%81%8A%E3%81%BE%E3%81%91git-config---local%E3%81%AE%E8%A8%AD%E5%AE%9A",
    "aria-label": "おまけgit config   localの設定 permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "おまけ（git config —localの設定）"), "\n", React.createElement(_components.p, null, "個人用のリポジトリでコミットログに表示されるユーザ名とemailを変えたい時に設定する\nリポジトリ毎に設定することができる"), "\n", React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<div class=\"gatsby-highlight\" data-language=\"bash\"><pre style=\"counter-reset: linenumber NaN\" class=\"language-bash line-numbers\"><code class=\"language-bash\"><span class=\"token function\">git</span> config <span class=\"token parameter variable\">--local</span> user.name <span class=\"token string\">\"サブアカウント\"</span>\n<span class=\"token function\">git</span> config <span class=\"token parameter variable\">--local</span> user.email <span class=\"token string\">\"サブアカウントメールアドレス\"</span>\n\n<span class=\"token function\">git</span> config <span class=\"token parameter variable\">--local</span> <span class=\"token parameter variable\">-l</span>\n<span class=\"token assign-left variable\">user.name</span><span class=\"token operator\">=</span><span class=\"token string\">\"サブアカウント\"</span>\n<span class=\"token assign-left variable\">user.email</span><span class=\"token operator\">=</span><span class=\"token string\">\"サブアカウントメールアドレス\"</span></code><span aria-hidden=\"true\" class=\"line-numbers-rows\" style=\"white-space: normal; width: auto; left: 0;\"><span></span><span></span><span></span><span></span><span></span><span></span></span></pre></div>"
    }
  }), "\n", React.createElement(_components.h2, {
    id: "参考",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#%E5%8F%82%E8%80%83",
    "aria-label": "参考 permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "参考"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://qiita.com/okappy/items/8fb3dacf176b45db85eb"
  }, "githubの複数アカウントの扱い方")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://qiita.com/colorrabbit/items/6c3e96c394bb0f753ea0"
  }, "[GitHub] 公開鍵登録の際に、Permission denied (publickey). が出続ける場合の対応 (vagrantからsshする場合の注意も)")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://qiita.com/0084ken/items/f4a8b0fbff135a987fea"
  }, "複数のgitアカウントを使い分ける")), "\n"), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
